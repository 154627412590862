<template>
  <div class="content">
    <!-- pc端 -->
    <div
      class="content_img"
      v-if="!MobileTerminal"
      @click="dialogFormVisible = true"
    >
      <img src="../assets/image/home-page.png" alt="" />
      <div class="request-btn" @click="dialogFormVisible = true">
        申请1个月免费试用
      </div>
    </div>
    <!-- 移动端 -->
    <div
      class="content_img"
      v-if="MobileTerminal"
      @click="dialogFormVisible = true"
    >
      <img src="../assets/images/home-page.png" alt="" />
    </div>
    <!-- pc端企业痛点 -->
    <div class="content1" v-if="!MobileTerminal">
      <div style="text-align: center">
        <div class="content_titles">
          <img class="title-img" src="../assets/image/Title.png" alt="" />
          <h3>企业痛点</h3>
        </div>
        <ul v-if="!MobileTerminal">
          <li
            class="Selected1"
            :style="Mouseenter !== 0 ? 'background:none ' : ''"
            @mouseenter="onMouseenter(0)"
          >
            <img
              src="../assets/image/Order1.png"
              v-if="Mouseenter == 0"
              alt=""
            />
            <div style="display: inline-block">
              <img
                src="../assets/image/Order.png"
                v-if="Mouseenter !== 0"
                alt=""
              />
            </div>
            <div style="white-space: nowrap" v-if="Mouseenter !== 0">
              软件多，数据不流通
            </div>
          </li>
          <li
            class="Selected2"
            :style="Mouseenter !== 1 ? 'background:none ' : ''"
            @mouseenter="onMouseenter(1)"
          >
            <img
              src="../assets/image/Procurement.png"
              v-if="Mouseenter == 1"
              alt=""
            />
            <div style="display: inline-block">
              <img
                src="../assets/image/Procurement1.png"
                v-if="Mouseenter !== 1"
                alt=""
              />
            </div>
            <div
              style="white-space: nowrap; display: block"
              v-if="Mouseenter !== 1"
            >
              收单问题
            </div>
          </li>
          <li
            class="Selected2"
            :style="Mouseenter !== 2 ? 'background:none ' : ''"
            @mouseenter="onMouseenter(2)"
          >
            <img
              src="../assets/image/Sorting.png"
              v-if="Mouseenter == 2"
              alt=""
            />
            <div style="display: inline-block">
              <img
                src="../assets/image/Sorting1.png"
                v-if="Mouseenter !== 2"
                alt=""
              />
            </div>
            <div
              style="white-space: nowrap; display: block"
              v-if="Mouseenter !== 2"
            >
              费用计算
            </div>
          </li>
          <li
            class="Selected2"
            :style="Mouseenter !== 3 ? 'background:none ' : ''"
            @mouseenter="onMouseenter(3)"
          >
            <img
              src="../assets/image/Distribution.png"
              v-if="Mouseenter == 3"
              alt=""
            />
            <div style="display: inline-block">
              <img
                src="../assets/image/Distribution1.png"
                v-if="Mouseenter !== 3"
                alt=""
              />
            </div>
            <div
              style="white-space: nowrap; display: block"
              v-if="Mouseenter !== 3"
            >
              发货问题
            </div>
          </li>
          <li
            class="Selected2"
            :style="Mouseenter !== 4 ? 'background:none ' : ''"
            @mouseenter="onMouseenter(4)"
          >
            <img src="../assets/image/Bill.png" v-if="Mouseenter == 4" alt="" />
            <div style="display: inline-block">
              <img
                src="../assets/image/Bill1.png"
                v-if="Mouseenter !== 4"
                alt=""
              />
            </div>
            <div
              style="white-space: nowrap; display: block"
              v-if="Mouseenter !== 4"
            >
              支付对账
            </div>
          </li>
          <li
            class="Selected3"
            :style="Mouseenter !== 5 ? 'background:none ' : ''"
            @mouseenter="onMouseenter(5)"
          >
            <img
              src="../assets/image/Settlement.png"
              style="margin-left: 3.75rem"
              v-if="Mouseenter == 5"
              alt=""
            />
            <div style="display: inline-block">
              <img
                src="../assets/image/Settlement1.png"
                v-if="Mouseenter !== 5"
                alt=""
              />
            </div>
            <div
              style="white-space: nowrap; display: block"
              v-if="Mouseenter !== 5"
            >
              结算分账
            </div>
          </li>
        </ul>
        <ul style="display: block" v-if="!MobileTerminal">
          <li v-if="Mouseenter == 0">
            <div class="span0">
              <img class="point" src="../assets/image/point.png" alt="" />
              <span class="span1">软件多，数据不流通</span>
              <span class="span2"
                >企业有多个系统，分别用于<br />
                库存、订单、财务、供应商数据不流通，加大工作难度。</span
              >
            </div>
          </li>
          <li v-if="Mouseenter == 1">
            <div class="span0 span01">
              <img
                class="point"
                style="left: 16.25rem"
                src="../assets/image/point.png"
                alt=""
              />
              <span class="span1">收单问题</span>
              <span class="span2"
                >微信、电话等传统收单方式,<br />
                无法高效汇总商品规格、数量，效率低下，出错导致用户情绪不满。</span
              >
            </div>
          </li>
          <li v-if="Mouseenter == 2">
            <div class="span0 span02">
              <img
                class="point"
                style="left: 30rem"
                src="../assets/image/point.png"
                alt=""
              />
              <span class="span1">费用计算</span>
              <span class="span2"
                >特定商品单点不送，不同区域不同售价<br />
                起送价，区域限购和计算价格较为复杂，人工易出错。</span
              >
            </div>
          </li>
          <li v-if="Mouseenter == 3">
            <div class="span0 span03">
              <img
                class="point"
                style="left: 43.75rem"
                src="../assets/image/point.png"
                alt=""
              />
              <span class="span1">发货问题</span>
              <span class="span2">
                企业仓库多、品类多，还有代仓、代配、<br />
                供应商供货等问题，需要快速汇总订单并且与各方对接。</span
              >
            </div>
          </li>
          <li v-if="Mouseenter == 4">
            <div class="span0 span04">
              <img
                class="point"
                style="left: 57.5rem"
                src="../assets/image/point.png"
                alt=""
              />
              <span class="span1">支付对账</span>
              <span class="span2"
                >线下收款资金安全存在风险，账期、各项资金核对复杂</span
              >
            </div>
          </li>
          <li v-if="Mouseenter == 5">
            <div class="span0 span05">
              <img
                class="point"
                style="left: 70.9375rem"
                src="../assets/image/point.png"
                alt=""
              />
              <span class="span1">结算分账</span>
              <span class="span2"
                >餐饮业商品多来自供应商，如货款都要入账后再支付<br />
                不仅流程繁琐，还额外多出许多成本。</span
              >
            </div>
          </li>
        </ul>
        <ul v-if="MobileTerminal" class="content1_label_ul">
          <li>
            <img src="../assets/image/Order.png" alt="" />
            <span class="span1">订单问题</span>
            <span class="span2">市场价格变动快，定价管理混乱</span>
          </li>
          <li>
            <img src="../assets/image/Procurement.png" alt="" />
            <span class="span1">采购问题</span>
            <span class="span2"
              >采购灰色收入难监管；采购成本高、核算不准确
            </span>
          </li>
          <li>
            <img src="../assets/image/Sorting.png" alt="" />
            <span class="span1">分拣发货问题</span>
            <span class="span2">多仓库或代仓，分拣步骤繁琐，耗时费力</span>
          </li>
          <li>
            <img src="../assets/image/Distribution.png" alt="" />
            <span class="span1">配送问题</span>
            <span class="span2">不同收货地址配送费不同，计算与收费复杂</span>
          </li>
          <li>
            <img src="../assets/image/Bill.png" alt="" />
            <span class="span1">财务对账</span>
            <span class="span2">线下收款资金安全存在风险，且核账麻烦</span>
          </li>
          <li>
            <img src="../assets/image/Settlement.png" alt="" />
            <span class="span1">供货结算</span>
            <span class="span2"
              >企业从供应商进行采购，再销售给门店，流程繁琐</span
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 移动端企业痛点 -->
    <div
      class="content1"
      style="width: 100%; margin-top: 1rem"
      v-if="MobileTerminal"
    >
      <div style="text-align: center">
        <div class="content_titles">
          <img class="title-img" src="../assets/image/Title.png" alt="" />
          <h3>企业痛点</h3>
        </div>
        <el-carousel
          height="15rem"
          indicator-position="none"
          ref="carousel"
          @change="carouselChange"
        >
          <el-carousel-item>
            <div class="Portable_Pain">
              <div>
                <img src="../assets/images/Order_.png" alt="" />
                软件多，数据不流通
              </div>
              企业有多个系统，分别用于库存、订单、财务、供应商，数据不流通，加大工作难度。
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="Portable_Pain">
              <div>
                <img src="../assets/images/Procurement_.png" alt="" />
                收单问题
              </div>
              微信、电话等传统收单方式，无法高效汇总商品规格、数量，效率低下，出错导致用户情绪不满
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="Portable_Pain">
              <div>
                <img src="../assets/images/Sorting_.png" alt="" />
                费用计算
              </div>
              特定商品单点不送，不同区域不同售价、起送价，区域限购和计算价格较为复杂，人工易出错
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="Portable_Pain">
              <div>
                <img src="../assets/images/Distribution_.png" alt="" />
                发货问题
              </div>
              企业仓库多、品类多，还有代仓、代配、供应商供货等问题，需要快速汇总订单并且与各方对接
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="Portable_Pain">
              <div>
                <img src="../assets/images/Bill_.png" alt="" />
                支付对账
              </div>
              线下收款资金安全存在风险，账期、各项资金核对复杂
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="Portable_Pain">
              <div>
                <img src="../assets/images/Settlement_.png" alt="" />
                结算分账
              </div>
              餐饮业商品多来自供应商，如货款都要入账后再支付，不仅流程繁琐，还额外多出许多成本
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- pc端货易链是什么 -->
    <div
      class="content2"
      v-if="!MobileTerminal"
      style="color: #000; margin: auto"
    >
      <div class="content_titles">
        <img class="title-img" src="../assets/image/Title1.png" alt="" />
        <h3 style="color: #000">货易链是什么？</h3>
      </div>
      <div class="hyl_div">
        <div class="hyl_">
          <div class="hyl_theMall" style="float: left">
            <img src="../assets/image/hyl_theMall.png" alt="" />
            <div>
              <ul>
                <li>前端商城接口</li>
                <li>ERP核心应用</li>
              </ul>
              <span>客户下单</span>
              <span>营销活动</span>
              <span>产品上下架</span>
            </div>
            <div class="hyl_appType">
              <div>
                <img src="../assets/image/app.png" alt="" />
                APP
              </div>
              <div>
                <img src="../assets/image/smallProgram.png" alt="" />
                小程序
              </div>
              <img
                class="shop_cart"
                src="../assets/image/shopCart.png"
                alt=""
              />
            </div>
          </div>

          <div class="hyl_what" style="float: right">
            <img src="../assets/image/hyl_what.png" alt="" />
            <span
              >是专为连锁加盟企业设计的在线订货商城，协助企业快速建立专属的电商订货平台。</span
            >
            <div>
              包含
              <span>建仓-采购-商品上架-用户下单付款-配送-进货</span>
              等一整套流程，主要
              <span
                >包含商家管理管理平台“货易链后台管理系统”、供应商供货平台“供应商后台系统”、客户下单“货易链客户端”、物流配送小程序“货易链物流”、业务跑单小程序“货易链业务端”。 </span
              >为传统企业的数字化赋能提供强劲的支撑和完善的服务，帮助企业不断提升经营效能。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端货易链是什么 -->
    <div
      class="content2"
      v-if="MobileTerminal"
      style="color: #000; margin: auto"
    >
      <div class="content_titles">
        <img class="title-img" src="../assets/image/Title1.png" alt="" />
        <h3 style="color: #000">货易链是什么？</h3>
      </div>
      <div style="width: 90%; margin: auto">
        <div class="">
          <div class="hyl_what1">
            <img src="../assets/image/hyl_what.png" alt="" />
            <span
              >是专为连锁加盟企业设计的在线订货商城，协助企业快速建立专属的电商订货平台。</span
            >
            <div>
              包含
              <span>建仓-采购-商品上架-用户下单付款-配送-进货</span>
              等一整套流程，主要
              <span
                >包含商家管理管理平台“货易链后台管理系统”、供应商供货平台“供应商后台系统”、客户下单“货易链客户端”、物流配送小程序“货易链物流”、业务跑单小程序“货易链业务端”。 </span
              >为传统企业的数字化赋能提供强劲的支撑和完善的服务，帮助企业不断提升经营效能。
            </div>
          </div>
          <div class="hyl_theMall1 bgg">
            <img src="../assets/image/hyl_theMall.png" alt="" />
            <div>
              <ul style="justify-content: left; margin-top: 1rem">
                <li>前端商城接口</li>
                <li>ERP核心应用</li>
              </ul>
              <span>客户下单</span>
              <span>营销活动</span>
              <span>产品上下架</span>
            </div>
            <div class="hyl_appType1">
              <div>
                <img src="../assets/image/app.png" alt="" />
                APP
              </div>
              <div>
                <img src="../assets/image/smallProgram.png" alt="" />
                小程序
              </div>
              <img
                class="shop_cart"
                src="../assets/image/shopCart.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- pc端系统架构 -->
    <div class="hyl_architecture content2" v-if="!MobileTerminal">
      <div class="content_titles">
        <img class="title-img" src="../assets/image/Title2.png" alt="" />
        <h3 style="color: #000">货易链-系统架构</h3>
      </div>
      <div>
        <ul>
          <li>
            <img src="../assets/image/Commodityimg.png" alt="" />
            <span class="hyl_architecture_title">商品</span>
            <span
              >售价自动调整<br />
              多计量单位/售价控制
            </span>
          </li>
          <li>
            <img src="../assets/image/Omsimg.png" alt="" />
            <span class="hyl_architecture_title">OMS</span>
            <span
              >批量核价/订单汇总<br />
              订单审核/订单退货
            </span>
          </li>
          <li>
            <img src="../assets/image/Crmimg.png" alt="" />
            <span class="hyl_architecture_title">CRM</span>
            <span
              >企业管理/客户分类管理<br />
              千人千价
            </span>
          </li>
          <li>
            <img src="../assets/image/Procurementimg.png" alt="" />
            <span class="hyl_architecture_title">采购</span>
            <span
              >智能采购计划/采购退货<br />
              按销售情况采购
            </span>
          </li>
          <li>
            <img src="../assets/image/Inventoryimg.png" alt="" />
            <span class="hyl_architecture_title">库存</span>
            <span
              >库存管理/批次库存<br />
              按类分拣/价值监管
            </span>
          </li>
          <li>
            <img src="../assets/image/Financeimg.png" alt="" />
            <span class="hyl_architecture_title">财务</span>
            <span
              >客户应收/采购应付<br />
              客户账期
            </span>
          </li>
          <li>
            <img src="../assets/image/Tmcimg.png" alt="" />
            <span class="hyl_architecture_title">TMC</span>
            <span
              >线路规划/司机轨迹
              <br />
              自动调配
            </span>
          </li>
          <li>
            <img src="../assets/image/Dataimg.png" alt="" />
            <span class="hyl_architecture_title">数据报表</span>
            <span
              >营业报表/业务报表<br />
              利润报表/数据大屏
            </span>
          </li>
        </ul>
        <ul>
          <li>
            基础设施
            <span
              >弹性云服务器 数据多重备份<br />
              负载均衡
            </span>
          </li>
          <li>
            货易链小程序
            <span>配送小程序 业务小程序</span>
          </li>
          <li>
            货易链小助手
            <span
              >商品云库 货到付款 企业代仓代配<br />供应商管理后台
              企业商品协助</span
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 移动端系统架构 -->
    <div
      class="hyl_architecture content2"
      style="margin-top: 1rem; background: #fff; height: auto"
      v-if="MobileTerminal"
    >
      <div class="content_titles">
        <img class="title-img" src="../assets/image/Title2.png" alt="" />
        <h3 style="color: #000; font-size: 3rem">货易链-系统架构</h3>
      </div>
      <div>
        <ul class="hyl_architecture_ul">
          <li>
            <img src="../assets/image/Commodityimg.png" alt="" />
            <span class="hyl_architecture_title">商品</span>
            <span
              >售价自动调整<br />
              多计量单位/售价控制
            </span>
          </li>
          <li>
            <img src="../assets/image/Omsimg.png" alt="" />
            <span class="hyl_architecture_title">OMS</span>
            <span
              >批量核价/订单汇总<br />
              订单审核/订单退货
            </span>
          </li>
          <li>
            <img src="../assets/image/Inventoryimg.png" alt="" />
            <span class="hyl_architecture_title">库存</span>
            <span
              >库存管理/批次库存<br />
              按类分拣/价值监管
            </span>
          </li>
          <li>
            <img src="../assets/image/Financeimg.png" alt="" />
            <span class="hyl_architecture_title">财务</span>
            <span
              >客户应收/采购应付<br />
              客户账期
            </span>
          </li>
          <li>
            <img src="../assets/image/Crmimg.png" alt="" />
            <span class="hyl_architecture_title">CRM</span>
            <span
              >企业管理/客户分类管理<br />
              千人千价
            </span>
          </li>
          <li>
            <img src="../assets/image/Procurementimg.png" alt="" />
            <span class="hyl_architecture_title">采购</span>
            <span
              >智能采购计划/采购退货<br />
              按销售情况采购
            </span>
          </li>

          <li>
            <img src="../assets/image/Tmcimg.png" alt="" />
            <span class="hyl_architecture_title">TMC</span>
            <span
              >线路规划/司机轨迹
              <br />
              自动调配
            </span>
          </li>
          <li>
            <img src="../assets/image/Dataimg.png" alt="" />
            <span class="hyl_architecture_title">数据报表</span>
            <span
              >营业报表/业务报表<br />
              利润报表/数据大屏
            </span>
          </li>
        </ul>
        <ul class="hyl_architecture_bottom">
          <li>
            基础设施
            <span>弹性云服务器 数据多重备份负载均衡 </span>
          </li>
          <li>
            货易链小程序
            <span>配送小程序 业务小程序</span>
          </li>
          <li>
            货易链小助手
            <span
              >商品云库 货到付款 企业代仓代配 供应商管理后台 企业商品协助</span
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- pc端货易链可以为企业提供什么? -->
    <div class="content3" v-if="!MobileTerminal">
      <div style="text-align: center" class="content_titles">
        <img class="title-img" src="../assets/image/Title3.png" alt="" />
        <h3 style="color: #000; display: inline-block">
          货易链可以为企业提供什么?
        </h3>
      </div>
      <div class="content3_title">
        <ul>
          <li
            v-for="(ite, index) in item"
            :key="index"
            :class="index == ProvideIndex ? 'content3_title_Selected' : ''"
            @click="Provide(ite, index)"
          >
            {{ ite.name }}
          </li>
        </ul>
      </div>
      <div class="content3_content">
        <div class="content3_content_name">
          <ul>
            <li style="list-style-type: initial">{{ Content.name }}</li>
          </ul>
        </div>
        <div class="content3_content_title">{{ Content.title }}</div>
      </div>
    </div>
    <!-- 移动端货易链可以为企业提供什么? -->
    <div class="content3" style="width: 100%" v-if="MobileTerminal">
      <div style="text-align: center">
        <img class="title-img" src="../assets/image/Title3.png" alt="" />
        <h3 style="color: #000; display: inline-block">
          货易链可以为企业提供什么?
        </h3>
      </div>
      <el-carousel height="20rem" indicator-position="none" ref="carousel">
        <el-carousel-item v-for="(ite, index) in item" :key="index">
          <div class="carousel_bg">
            <div>
              <img :src="ite.image" alt="" />
              {{ ite.name }}
            </div>
            {{ ite.title }}
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- pc端视频介绍 -->
    <div class="content3_1" v-if="!MobileTerminal">
      <div style="text-align: center">
        <div style="text-align: center" class="content_titles">
          <img
            class="title-img"
            style="width: 4rem"
            src="../assets/image/Title4.png"
            alt=""
          />
          <h3 style="color: #000; display: inline-block">视频介绍</h3>
        </div>
        <el-carousel
          height="37.5rem"
          indicator-position="outside"
          ref="carousel"
          arrow="always"
          @change="carouselChange"
        >
          <el-carousel-item v-for="(items, index) in lunbo" :key="index">
            <div class="lunbo">
              <div class="lunbo-left">
                <video
                  controls="controls"
                  :poster="items.Poster"
                  class="videos"
                  :src="items.MP4"
                ></video>
              </div>
              <div class="lunbo-right">
                <img class="part" :src="items.IMG" alt="" />
                <span
                  :style="
                    index == 0
                      ? ''
                      : 'white-space:nowrap;position: relative;left:-1.875rem;'
                  "
                  >{{ items.title }}</span
                >
                <img class="QR_code" src="../assets/image/QR_code.png" alt="" />
                <img
                  class="Attention"
                  src="../assets/image/Attention.png"
                  alt=""
                />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- pc端使用货易链收费 -->
    <div class="hyl_charges" v-if="!MobileTerminal">
      <div class="hyl_charges_title">使用<span>货易链</span>收费</div>
      <div class="hyl_charges_title1">
        一次性支付<span>3年</span>费用，<span>赠送1年</span>使用支付费率<span>0.6%</span>，量大可<span
          >优惠</span
        >
      </div>
      <div class="hyl_charges_title2">
        1000<span>元/月</span>12000<span>元/年</span>
      </div>
      <div class="hyl_charges_title3">商品合作，次日达</div>
      <div class="hyl_charges_title4">
        <div>企业个性化定制</div>
        <div>
          支持个性化定制服务，根据企业品牌文化，对接<span>企业公众号，搭建客户端、管理后台</span>、打造专属
          于企业的货易链体系。
        </div>
        <div>企业个性化定制</div>
      </div>
    </div>
    <!-- 移动端使用货易链收费 -->
    <div class="content3" style="width: 100%" v-if="MobileTerminal">
      <div style="text-align: center">
        <img class="title-img" src="../assets/image/Title6.png" alt="" />
        <h3 style="color: #000; display: inline-block">
          使用收费
        </h3>
      </div>
    <div
      class="hyl_charges"
      style="width: 100%; margin-top:1rem; padding:2rem 0;height: auto"
    >
      <div
        class="hyl_charges_title"
        style="font-size: 2.1rem; margin-bottom: 2rem"
      >
        使用<span>货易链</span>收费
      </div>
      <div
        class="hyl_charges_title1"
        style="font-size: 1.2rem; line-height: 1.8rem"
      >
        一次性支付<span>3年</span>费用，<span>赠送1年</span>使用支付费率<span>0.6%</span>，量大可<span
          >优惠</span
        >
      </div>
      <div class="hyl_charges_title2" style="font-size: 1.8rem">
        1000<span style="font-size: 1.2rem">元/月</span>12000<span
          style="font-size: 1.2rem"
          >元/年</span
        >
      </div>
      <div
        class="hyl_charges_title3 hyl_charges_title3_1"

      >
        商品合作，次日达
      </div>
      <div
        class="hyl_charges_title4"
        style="width: 90%; height:9rem; padding: 1.4rem"
      >
        <div style="font-size: 1.4rem">企业个性化定制</div>
        <div style="font-size: 1.2rem">
          支持个性化定制服务，根据企业品牌文化，对接<span>企业公众号，搭建客户端、管理后台</span>、打造专属
          于企业的货易链体系。
        </div>
      </div>
    </div>
  </div>

    <!-- pc端合作企业 -->
    <div
      class="content1"
      v-if="!MobileTerminal"
      :style="MobileTerminal ? 'width:100%;margin:0' : ''"
    >
      <div style="text-align: center">
        <div class="content_titles">
          <img class="title-img" src="../assets/image/Title5.png" alt="" />
          <h3>合作企业</h3>
        </div>
        <div>
          <div class="chinaecharts">
            <div
              style="width: 100%; height: 28.125rem; background-color: red"
            ></div>
            <div class="mapChart" id="mapChart" ref="mapChart"></div>
            <div class="enterprise">
              <span style="display: block; text-indent: 1em"
                >截至2022年底，已与40000＋家企业建立合作关系，覆盖城市：</span
              >
              <span
                style="display: block; text-indent: 1em; margin-top: 1.875rem"
              >
                浙江（杭州、嘉兴、台州、绍兴、宁波、湖州），安徽（合肥、宣城、安庆），江苏（苏州），陕西（西安），北京，湖南（郴州、长沙）、福建（福州）、湖北（武汉）、江西（景德镇）、广东（深圳、佛山）。
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端合作企业 -->
    <div
      class="content1"
      v-if="MobileTerminal"
      :style="MobileTerminal ? 'width:100%;margin:0' : ''"
    >
      <div style="text-align: center">
        <div class="content_titles">
          <img class="title-img" src="../assets/image/Title5.png" alt="" />
          <h3>合作企业</h3>
        </div>
        <div>
          <div
            class="chinaecharts"
            style="
              width: 90%;
              margin: auto;
              background-color: red;
              height: auto;
              padding: 0 13px 25px;
              box-sizing: border-box;
              border-radius: 15px;
              margin-bottom: 1rem;
            "
          >
            <div style="width: 100%; height: 35rem" class="mapChart_bg"></div>
            <div
            class="mapChart1"
              id="mapChart"
              ref="mapChart"
            ></div>
            <div
              style="
                /* width: 400px; */
                /* position: absolute;
                top: 53px;
                right: 43px; */
                color: #ffffff;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                text-align: left;
              "
            >
              <span style="display: block; text-align: left; text-indent: 1em"
                >截至2022年底，已与40000＋家企业建立合作关系，覆盖城市：
                浙江（杭州、嘉兴、台州、绍兴、宁波、湖州），安徽（合肥、宣城、安庆），江苏（苏州），陕西（西安），北京，湖南（郴州、长沙）、福建（福州）、湖北（武汉）、江西（景德镇）、广东（深圳、佛山）。
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require('echarts')
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      Mouseenter: 0,
      ProvideIndex: 0,
      Content: {
        name: '集成式系统，功能完善',
        title:
          '货易链是一套综合性的系统软件，一套系统实现专业订货商城、访销CRM、进销存、财务资金、分拣配送、业务报表全业务场景的一体化数字管控，颠覆传统ERP，让生意更简单!“利润分析”一张表，老板实时掌握企业经营情况。'
      },
      item: [
        {
          name: '集成式系统，功能完善',
          title:
            '货易链是一套综合性的系统软件，一套系统实现专业订货商城、访销CRM、进销存、财务资金、分拣配送、业务报表全业务场景的一体化数字管控，颠覆传统ERP，让生意更简单!“利润分析”一张表，老板实时掌握企业经营情况。',
          index: 0,
          image: require('../assets/images/part1.png')
        },
        {
          name: '高效收单',
          title:
            '系统汇总订单信息，智能派发货单至供应商，代替传统线下汇总商品再发送至供应商，核对账目不易出错更高效。',
          index: 1,
          image: require('../assets/images/part2.png')
        },
        {
          name: '订单结算',
          title:
            '各种经营模式均适用，客户下单金额自动计算，支持微信、支付宝等第三方渠道付款，也支持货到付款，每位客户可单独设置账期、资金，个性化管理。支持多种业务模式，线上线下深度融合。',
          index: 2,
          image: require('../assets/images/part3.png')
        },
        {
          name: '分拣备货',
          title:
            '支持与合作企业协作代仓代配；订单汇总，按照仓库、品类、配送员进行备货，分拣更快捷；根据订单地点配置线路，自动配置司机减少人工排单',
          index: 3,
          image: require('../assets/images/part4.png')
        },
        {
          name: '智能分账',
          title:
            '订单结算、货单结算，减缓财务核对、催款压力，平台自动划付，省时省力更省心。',
          index: 4,
          image: require('../assets/images/part5.png')
        },
        {
          name: '营销多样',
          title:
            '超值组合、秒杀、满赠、优惠券等上游营销策略直接下发至终端商城，快速触达客户；千人千价，根据用户购买情况设置价格，可高可低，分批次管理。',
          index: 5,
          image: require('../assets/images/part6.png')
        },
        {
          name: '多维度数据分析',
          title:
            '帮助企业了解商品、用户、订单、业务数据情况；利润分析直观展示企业经营情况。',
          index: 6,
          image: require('../assets/images/part7.png')
        },
        {
          name: '商品合作',
          title:
            '有较多的合作企业和商品渠道，有意向可提供商品供货，促成多方企业合作。',
          index: 7,
          image: require('../assets/images/part8.png')
        },
        {
          name: '专业支持',
          title:
            '有专业的技术服务团队，帮助企业搭建自己的电商运营体系，部署、实施、运营远程指导；每次更新均有线上线下会议，助力企业经营。',
          index: 8,
          image: require('../assets/images/part9.png')
        }
      ],
      lunbo: [
        {
          title: '货易链是什么？',
          MP4: require('../assets/image/What.mp4'),
          IMG: require('../assets/image/part1.png'),
          Poster: require('../assets/image/Poster1.png')
        },
        {
          title: '传统企业痛点是什么？',
          MP4: require('../assets/image/painPoint.mp4'),
          IMG: require('../assets/image/part2.png'),
          Poster: require('../assets/image/Poster2.png')

        },
        {
          title: '我们的软件优势是什么？',
          MP4: require('../assets/image/Advantage.mp4'),
          IMG: require('../assets/image/part3.png'),
          Poster: require('../assets/image/Poster3.png')

        }
      ],
      act: 0
    }
  },
  mounted () {
    this.mapFn()
  },
  methods: {
    carouselChange (e) {
      this.act = e
    },
    setActiveItem (e) {
      this.$refs.carousel.setActiveItem(e)
    },
    onMouseenter (index) {
      this.Mouseenter = index
    },
    Provide (ite, index) {
      this.Content = ite
      this.ProvideIndex = index
    },
    mapFn () {
      // 基于准备好的dom，初始化echarts实例
      // var mapChart = this.$echarts.init(this.$refs.mapChart);
      var myChart = echarts.init(this.$refs.mapChart)
      var geoCoordMap = {
        浙江省湖州市: [120.1, 30.86],
        浙江省嘉兴市: [120.76, 30.77],
        浙江省杭州市: [120.19, 30.26],
        浙江省绍兴市: [120.58, 30.01],
        浙江省台州市: [121.420757, 28.656386],
        浙江省宁波市: [121.56, 29.86],
        江西省景德镇市: [117.184, 29.274],
        安徽省合肥市: [117.17, 31.52],
        安徽省宣城市: [118.765534, 30.9466],
        安徽省安庆市: [117.05, 30.53],
        湖南省郴州市: [113.13, 25.52],
        湖北省武汉市: [114.19, 30.33],
        广东省佛山市: [113.11, 23.05],
        江苏省苏州市: [120.58, 31.3],
        陕西省西安市: [108.55, 34.32],
        北京市: [116.3, 39.9],
        湖南省长沙市: [112.98, 28.19],
        福建省福州市: [119.28, 26.08],
        广东省深圳市: [114.05, 22.55]
      }

      var convertData = function (data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push(geoCoord.concat(data[i].name))
          }
        }
        return res
      }

      var option = {
        backgroundColor: 'transparent',
        title: {
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: function (res) {
            return res.data[2]
          }
        },
        legend: {
          show: false
        },
        visualMap: {
          show: false
        },
        geo: {
          map: 'china',
          label: {
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#FFF',
              borderColor: '#FF5454'
            },
            emphasis: {
              areaColor: '#FFF'
            }
          },
          regions: [
            {
              name: '南海诸岛',
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 0 // 为 0 时不绘制该图形
                }
              },
              label: {
                show: false // 隐藏文字
              }
            }
          ]
        },
        series: [
          {
            name: '',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: convertData([
              { name: '湖北省武汉市' },
              { name: '湖南省郴州市' },
              { name: '江西省景德镇市' },
              { name: '广东省佛山市' },
              { name: '浙江省湖州市' },
              { name: '浙江省嘉兴市' },
              { name: '浙江省宁波市' },
              { name: '浙江省台州市' },
              { name: '浙江省绍兴市' },
              { name: '浙江省杭州市' },
              { name: '安徽省合肥市' },
              { name: '安徽省宣城市' },
              { name: '安徽省安庆市' },
              { name: '江苏省苏州市' },
              { name: '陕西省西安市' },
              { name: '北京市' },
              { name: '湖南省长沙市' },
              { name: '福建省福州市' },
              { name: '广东省深圳市' }
            ]),
            symbolSize: 6,
            color: '#005AFF'
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>
<style scoped>
.request-btn {
  position: absolute;
  left: 18.75rem;
  bottom: 6.25rem;
  width: 20.625rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.875rem;
  color: #ee4c00;
}
.content1 {
  width: 75rem;
  margin: 5rem auto 0;
}
.content_titles {
  margin-bottom: 1.875rem;
}
.title-img {
  display: inline-block;
  width: 3rem;
  height: 3.25rem;
  vertical-align: middle;
}
.content1 h3,
.content2 h3,
.content3 h3 {
  font-size: 2.875rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #111111;
  margin: 0.625rem 0 0.625rem 0.8rem;
  display: inline-block;
  vertical-align: middle;
}
.content1 ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.content1 ul li {
  box-sizing: border-box;
}
.content1 ul .Selected2 > img {
  margin: 1.25rem auto 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.content1 ul li img {
  width: 4.5rem;
  height: 4.5rem;
  float: left;
  margin-top: 1rem;
}
.content1 ul li .point {
  width: 1.375rem;
  height: 4.5rem;
  position: absolute;
  top: -3.75rem;
  left: 2.8125rem;
}
.content1 ul li .point1 {
  width: 1.375rem;
  height: 4.5rem;
  position: absolute;
  top: -3.75rem;
  left: 21.875rem;
}
.content1 ul li .span1 {
  font-size: 2.25rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff0000;
  line-height: 2.5rem;
}
.content1 ul li .span2 {
  font-size: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  line-height: 2.5rem;
  display: block;
  margin-top: 2.25rem;
}

.content1 ul li .span0 {
  background: url(../assets/image/order-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 15.5rem;
  padding: 3.125rem;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}
.content1 ul li .span01 {
  background: url(../assets/image/Procurement_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content1 ul li .span02 {
  background: url(../assets/image/Sorting-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content1 ul li .span03 {
  background: url(../assets/image/Distribution_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content1 ul li .span04 {
  background: url(../assets/image/Bill_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content1 ul li .span05 {
  background: url(../assets/image/Settlement_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content1_label_ul {
  font-size: 0.625rem;
}
.content1_label_ul > li {
  padding: 0 !important;
}
.Selected1 {
  width: 9.375rem;
  height: 8.75rem;
  padding-left: 1.25rem;
  background: url(../assets/image/selected.png) no-repeat;
  background-size: 100% 100%;
  /* display: flex; */
}
.Selected2 {
  width: 11.875rem;
  height: 8.75rem;
  text-align: center;
  /* padding-left:20px; */
  background: url(../assets/image/selected2.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.Selected3 {
  width: 9.375rem;
  height: 8.75rem;
  background: url(../assets/image/selected3.png) no-repeat;
  background-size: 100% 100%;
}
/* 货易链是什么 */
.content2 {
  width: 100%;
  /* height: 26.25rem; */
  text-align: center;
  color: #fff;
  margin-top: 5rem;
  padding-top: 2.375rem;
  position: relative;
}

.content2 ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2.75rem;
  box-sizing: border-box;
}
.content2 ul li {
  width: 18.0625rem;
  padding: 0 1.875rem;
  box-sizing: border-box;
}
.content2 ul li img {
  width: 8.25rem;
  height: 8.25rem;
}
.content2 ul li span {
  display: block;
  margin-top: 1.375rem;
  font-size: 1rem;
  color: #fff;
}
.hyl_div {
  width: 100%;
  height: 37.5rem;
  background: url(../assets/image/home-page1.png) no-repeat;
  background-size: 100% 100%;
}

.hyl_ {
  width: 75rem;
  margin: auto;
  text-align: center;
  position: relative;
}
.hyl_what {
  background: linear-gradient(#ff6d69, #ff0000);
  width: 37.5rem;
  height: 37.5rem;
  border-radius: 1.25rem;
  position: absolute;
  top: 5rem;
  right: 3.75rem;
  padding: 5rem 1.875rem;
  box-sizing: border-box;
  font-size: 1.375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 2.75rem;
  text-align: left;
}

.hyl_what > img {
  width: 10rem;
  height: 3rem;
}
.hyl_what div {
  margin-top: 1.875rem;
}
.hyl_what div span {
  font-weight: 600;
}
.hyl_theMall {
  background: linear-gradient(#fffaf9, #fde4e1);
  width: 37.5rem;
  height: 37.5rem;
  border-radius: 1.25rem;
  padding: 3.75rem 1.875rem;
  box-sizing: border-box;
  text-align: left;
}
.hyl_theMall > img {
  width: 16.5rem;
  height: 3rem;
}
.hyl_theMall ul li {
  list-style-type: initial;
  font-size: 1.875rem;
  font-family: Microsoft YaHei;
  color: #ff0000;
  padding: 0 !important;
  margin-left: 1.875rem;
}
.hyl_theMall span {
  display: block;
  font-size: 1.5rem;
  font-family: Microsoft YaHei;
  color: #727272;
  line-height: 2.5rem;
  margin-left: 1.875rem;
}
.hyl_appType {
  margin-top: 3.75rem;
  position: relative;
}
.hyl_appType > div img {
  width: 1.75rem;
  height: 1.75rem;
  vertical-align: middle;
}
.hyl_appType > div {
  width: 12.5rem;
  height: 3.125rem;
  line-height: 3.125rem;
  padding-left: 0.875rem;
  background: linear-gradient(90deg, #ff0000 0%, rgba(255, 42, 0, 0.1) 100%);
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-family: Microsoft YaHei;
  color: #ffffff;
}

.shop_cart {
  width: 14.5rem;
  height: 15.5rem;
  position: absolute;
  bottom: 0;
  left: 55%;
  z-index: 3;
}
/* 系统架构 */
.hyl_architecture {
  width: 100%;
  height: 53.125rem;
  background: url(../assets/image/architecture-bg.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 9.375rem;
  text-align: center;
}
.hyl_architecture div ul {
  width: 75rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.hyl_architecture div ul:nth-child(1) li {
  margin-bottom: 3.125rem;
  width: 25%;
}
.hyl_architecture div ul:nth-child(1) li img {
  width: 4.0625rem;
  height: 4.0625rem;
}
.hyl_architecture div ul:nth-child(1) li .hyl_architecture_title {
  font-size: 1.625rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff0101;
  line-height: 2.875rem;
  display: block;
}
.hyl_architecture div ul:nth-child(1) li span {
  font-size: 1.25rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #767676;
  line-height: 2.25rem;
  text-align: center;
  display: block;
}
.hyl_architecture div ul:nth-child(2) li {
  width: 32%;
  height: 10rem;
}
.hyl_architecture div ul:nth-child(2) li {
  font-size: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff0101;
  padding: 1.8125rem 1.625rem;
  text-align: left;
  box-sizing: border-box;
}
.hyl_architecture div ul:nth-child(2) li span {
  font-size: 1.25rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #767676;
  line-height: 2.25rem;
  display: block;
  margin-top: 0.625rem;
}
.hyl_architecture div ul:nth-child(2) li:nth-child(1) {
  background: url(../assets/image/Infrastructure.png) no-repeat;
  background-size: 100% 100%;
}
.hyl_architecture div ul:nth-child(2) li:nth-child(2) {
  background: url(../assets/image/barterChainApplet.png) no-repeat;
  background-size: 100% 100%;
}
.hyl_architecture div ul:nth-child(2) li:nth-child(3) {
  background: url(../assets/image/barterChainAssistant1.png) no-repeat;
  background-size: 100% 100%;
}
/* 货易链可以为企业提供什么 */
.content3 {
  width: 75rem;
  overflow: hidden;
  margin: 5rem auto 0;
  position: relative;
}
.content3 .lunbo > img {
  float: right;
  width: 50%;
  /* height: 19.125rem; */
}
.content3 .lunbo-left {
  float: left;
  width: 45%;
  text-align: left;
  margin-top: 5.625rem;
}
.content3 .lunbo-left span {
  width: 22.5rem;
  display: inline-block;
  color: #999999;
}
/* .content3_1 .lunbo-left-title,
.content3 .lunbo-left-title {
  width: 7.9375rem;
  height: 2.8125rem;
  line-height: 2.8125rem;
  text-align: center;
  background: #ffeee5;
  border-radius: 1.625rem;
  font-size: 1.25rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff7100;
  margin-bottom: 1.375rem;
} */
.content3_title {
  width: 75rem;
  height: 42.5rem;
  background: #fffafa;
  border-radius: 1.25rem;
  background: url(../assets/image/provideDemand-bg.png) no-repeat;
  background-size: 102% 100%;
  padding: 1.125rem 2rem;
  box-sizing: border-box;
}
.content3_title ul {
  font-size: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* display: flex; */
  /* width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 1.375rem;
  transform: translateX(-50%);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500; */
}
.content3_title_Selected {
  width: 21.875rem;
  background-color: #ff6565;
  border-radius: 0.625rem;
  margin: 0.625rem 0;
  padding-right: 1.875rem;
  box-sizing: border-box;
}
.content3_title_Selected::after {
  content: "→";
  float: right;
}
.content3_content {
  position: absolute;
  top: 9.375rem;
  right: 4.0625rem;
  width: 39.375rem;
  text-align: left;
}
.content3_content_name {
  font-size: 1.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff2222;
  margin-left: 1.875rem;
}
.content3_content_title {
  font-size: 1.375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #767676;
  line-height: 3.125rem;
}
.content3_title ul li {
  padding-left: 1.25rem;
  height: 3.75rem;
  line-height: 3.75rem;
}
/* 视频介绍 */
.content3_1 {
  width: 100%;
  overflow: hidden;
  margin-top: 1.25rem;
}

.content3_1 h3 {
  color: #111111;
  font-size: 2.875rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  margin: 0.625rem 0 0.625rem 0.8rem;
  vertical-align: middle;
}

.content3_1 .lunbo {
  width: 75rem;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.content3_1 .lunbo > img {
  width: 100%;
  max-width: 25rem;
}

.content3_1 .lunbo-left {
  /* padding-left: 10px; */
  text-align: left;
}

.content3_1 .lunbo-left span {
  display: inline-block;
  color: #999999;
}

.lunbo-left {
  background-color: #000;
  border-radius: 1.25rem;
}
.videos {
  width: 50rem;
  height: 28.125rem;
  margin-top: 4.6875rem;
  object-fit: cover;
}
.lunbo-right {
  width: 23.75rem;
  height: 37.5rem;
  border: 1px dashed #ff0000;
  background: linear-gradient(0deg, #fff0f0 0%, #ffffff 100%);
  border-radius: 1.25rem;
  padding: 3.75rem 5rem 0;
  text-align: center;
  box-sizing: border-box;
  font-size: 1.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff2222;
}
.part {
  width: 8.75rem;
  height: 3.625rem;
  display: block;
  margin: auto;
  margin-bottom: 1.25rem;
}
.QR_code {
  width: 13.75rem;
  height: 13.75rem;
  margin: 3.75rem 0 1.5625rem;
}
.Attention {
  width: 13.125rem;
  height: 4.375rem;
}
/* 使用货易链收费 */

.hyl_charges {
  width: 100%;
  height: 50rem;
  background: url(../assets/image/charges-bg.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 9.375rem;
  text-align: center;
  padding-top: 7.5rem;
  box-sizing: border-box;
}
.hyl_charges .hyl_charges_title {
  font-size: 4.375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  margin-bottom: 2.5rem;
}
.hyl_charges .hyl_charges_title span {
  height: 6.25rem;
  padding: 1rem 1.875rem;
  background: linear-gradient(90deg, #fe3838 0%, #ff9191 100%);
  color: #fff;
  border-radius: 3.75rem;
  border-top-left-radius: 4.375rem;
  border-bottom-left-radius: 1.25rem;
}
.hyl_charges .hyl_charges_title1 {
  font-size: 1.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  line-height: 4.125rem;
}
.hyl_charges .hyl_charges_title1 span {
  color: #ff2222;
}
.hyl_charges .hyl_charges_title2 {
  font-size: 2.5rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff2222;
  line-height: 4.125rem;
  margin-top: 0.625rem;
}
.hyl_charges .hyl_charges_title2 span {
  font-size: 1.5rem;
  color: #414141;
  margin-right: 1.875rem;
}
.hyl_charges .hyl_charges_title3 {
  display: inline-block;
  padding: 0 1.25rem;
  height: 4.375rem;
  background: #ff2222;
  font-size: 2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 4.125rem;
  margin-bottom: 3.75rem;
}
.hyl_charges .hyl_charges_title4 {
  width: 75rem;
  height: 12.5rem;
  background: #fff7f7;
  border: 0.375rem solid #ff2222;
  border-radius: 1.875rem;
  margin: auto;
  padding: 2.5rem 2.75rem;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}
.hyl_charges .hyl_charges_title4 div:nth-child(1) {
  font-size: 2.25rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #414141;
}
.hyl_charges .hyl_charges_title4 div:nth-child(2) {
  font-size: 1.625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #787878;
}
.hyl_charges .hyl_charges_title4 div:nth-child(2) span {
  color: #ff2222;
}
.hyl_charges .hyl_charges_title4 div:nth-child(3) {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 0 1.25rem;
  border-radius: 0 1.25rem 0 1.25rem;
  font-size: 1.875rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 3.5rem;
  background-color: #ff2222;
}
/* 地图 */
.chinaecharts {
  /* width: 1200px;
  height: 570px; */
  width: 75rem;
  height: 35.625rem;
  position: relative;
  overflow: hidden;
}
#mapChart {
  /* width: 700px;
  height: 475px; */
  width: 87.5rem;
  height: 56.25rem;
  position: absolute;
  top: -16.875rem;
  left: -25rem;
}
.enterprise {
  width: 25rem;
  position: absolute;
  top: 3.3125rem;
  right: 2.6875rem;
  color: #ffffff;
  font-size: 1.375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 2.5rem;
  text-align: left;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 0.875rem;
  opacity: 0.75;
  line-height: 9.375rem;
  margin: 0;
}

.bgg {
  background: url(../assets/image/what_bg.png) no-repeat;
  background-size: 100% 100%;
}

/* .anchorBL {
  display: none;
} */
@media only screen and (max-width: 1500px) {
  .request-btn {
    position: absolute;
    left: 15.625rem;
    bottom: 3.75rem;
  }
}
@media only screen and (max-width: 768px) {
  .mapChart1 {
  width: 160% !important;
  height: 160% !important;
  position: absolute;
  top: -26.875rem !important;
  left:-30% !important;
}
h3{
  font-size: 2.5rem !important;
}
}
@media only screen and (max-width:400px){
  .mapChart_bg{
height: 30rem !important;
  }
  h3{
    font-size: 22px !important;
  }
}
/* 移动端 */
.Portable_Pain {
  text-align: left;
  padding: 2rem 1.8rem;
  background-color: #fff6f6;
  width: 90%;
  height: 100%;
  margin: auto;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  line-height: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
}
.Portable_Pain div {
  font-size: 1.3rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff0202;
  margin-bottom: 1.3rem;
}
.Portable_Pain img {
  width: 1.6rem;
  height: 1.6rem;
  vertical-align: text-top;
}
.hyl_what1 {
  width: 100%;
  position: unset;
  padding: 20px 18px;
  font-size: 11px;
  line-height: 22px;
  height: auto;
  background: linear-gradient(#ff6d69, #ff0000);
  border-radius: 1.25rem;
  box-sizing: border-box;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
}
.hyl_what1 > img {
  width: 8rem;
  height: 2.4rem;
}
.hyl_what1 div {
  margin-top: 1.2rem;
}
.hyl_theMall1 {
  background: linear-gradient(#fffaf9, #fde4e1);
  border-radius: 1.25rem;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  position: unset;
  padding: 2rem 1.8rem;
  font-size: 1.1rem;
  line-height: 2.2rem;
  height: auto;
  margin-top: 1.2rem;
}
.hyl_theMall1 > img {
  width: 13.2rem;
  height: 2.4rem;
}
.hyl_theMall1 ul li {
  list-style-type: initial;
  font-family: Microsoft YaHei;
  color: #ff0000;
  padding: 0 !important;
  margin-left: 1.875rem;
  font-size: 1.5rem;
  width: 9.9rem;
}
.hyl_theMall1 span {
  display: block;
  font-family: Microsoft YaHei;
  color: #727272;
  margin-left: 1.875rem;
  font-size: 1.2rem;
  margin-left: 2.8rem;
  line-height: 2.2rem;
}
.hyl_appType1 {
  margin-top: 2rem;
  position: relative;
  font-size: 1.4rem;
}
.hyl_appType1 > div img {
  width: 1.3rem;
  height: 1.5rem;
  vertical-align: middle;
}
.hyl_appType1 > div {
  width: 10rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 0.875rem;
  background: linear-gradient(90deg, #ff0000 0%, rgba(255, 42, 0, 0.1) 100%);
  margin-bottom: 1.25rem;
  font-size: 1.4rem;
  font-family: Microsoft YaHei;
  color: #ffffff;
}
.hyl_architecture div .hyl_architecture_ul {
  width: 90% !important;
}
.hyl_architecture div .hyl_architecture_ul li:nth-child(1) {
  margin-bottom: 2.5rem !important;
}
.hyl_architecture div .hyl_architecture_ul li {
  width: 50% !important;
  font-size: 1.3rem;
  line-height: 3.3rem;
}
.hyl_architecture div .hyl_architecture_ul li span {
  font-size: 1rem !important;
  line-height: 1.8rem !important;
}
.hyl_architecture div .hyl_architecture_bottom {
  width: 90%;
  display: block;
  margin: auto;
}
.hyl_architecture div .hyl_architecture_bottom li {
  width: 100% !important;
  height: 8rem;
  padding: 1.45rem 1.55rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.hyl_architecture div .hyl_architecture_bottom li:nth-child(1) {
  background: url(../assets/image/Infrastructure1.png) no-repeat !important;
  background-size: 100% 100% !important;
}
.hyl_architecture div .hyl_architecture_bottom li:nth-child(2) {
  background: url(../assets/image/barterChainApplet1.png) no-repeat !important;
  background-size: 100% 100% !important;
}
.hyl_architecture div .hyl_architecture_bottom li:nth-child(3) {
  background: url(../assets/image/barterChainAssistant1.png) no-repeat !important;
  background-size: 100% 100% !important;
}
.hyl_architecture div .hyl_architecture_bottom li span {
  font-size: 1rem;
  line-height: 1.8rem;
  margin-top: 0.5rem;
}
.carousel_bg {
  background: url(../assets/image/carousel_bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: left;
  padding: 2rem 1.8rem;
  width: 90%;
  height: 100%;
  margin: auto;
  font-size: 1rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
  line-height: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
}
.carousel_bg div {
  font-size: 1.3rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff0202;
  margin-bottom: 1.3rem;
}
.carousel_bg img {
  width: 4rem;
  height: 1.8rem;
  vertical-align: bottom;
}
.hyl_charges_title3_1{
          height: 3rem;
          background: #ff2222;
          font-size: 1.3rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 3rem;
          padding: 0;
          margin-bottom: 1.5rem;
}
</style>
