<template>
  <div class="content">
    <div class="content_img" v-if="!MobileTerminal">
      <img src="../assets/image/registered.png" alt="" />
    </div>
    <div class="content-title" v-if="!MobileTerminal">
      <img
        style="margin: auto; width: 11.375rem; height: 5.25rem"
        src="../assets/image/registered1.png"
        alt=""
      />
    </div>
    <div class="content-title1" v-if="MobileTerminal">企业入驻申请表</div>
    <div class="content-form">
      <div class="form-label">
        <span>图片上传说明： </span>
        <span>1、保证上传证件在有效期</span>
        <span
          >2、上传图片支持 jpg、jpeg、png、gif 格式，每张图片大小应<span
            style="color: #ee2a00"
            >小于4M</span
          >。</span
        >
        <span>3、支持上传资料的原件彩色扫描件或照片。</span>
        <span
          >4、为保证您信息正常审核通过，请上传清晰、完整、有效的图片资料。</span
        >
        <span style="color: #ee2a00"
          >注意：手持个人证件照片，自行进行拍照。需保证所持证件和人脸清晰、完整、无遮挡。</span
        >
      </div>
      <div
        class="form-input"
        :style="MobileTerminal ? 'padding:18px 23px' : ''"
      >
        <span :style="MobileTerminal ? 'font-size:17px;' : ''"
          >企业信息<span style="color: #ee2a00"
            >*（企业信息均为必填项）</span
          ></span
        >
        <el-form
          class="el_form"
          :inline="true"
          :rules="rules1"
          ref="form1"
          label-position="top"
          :model="form1"
          hide-required-asterisk
        >
          <el-form-item label="公司名称" prop="companyName">
            <el-input
              :style="MobileTerminal ? 'width:300px' : ''"
              class="el_input"
              v-model="form1.companyName"
              placeholder="请输入公司名称"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="联系人" prop="contactName">
            <el-input
              :style="MobileTerminal ? 'width:300px' : ''"
              class="el_input"
              placeholder="请输入联系人"
              v-model="form1.contactName"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="地址" prop="companyAddress">
            <el-input
              :style="MobileTerminal ? 'width:300px' : ''"
              class="el_input"
              placeholder="请输入公司地址"
              v-model="form1.companyAddress"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="联系方式" prop="contactPhone">
            <el-input
              :style="MobileTerminal ? 'width:300px' : ''"
              class="el_input"
              v-model="form1.contactPhone"
              placeholder="请输入联系方式"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="公司简称" prop="shortName">
            <el-input
              :style="MobileTerminal ? 'width:300px' : ''"
              class="el_input"
              placeholder="请输入公司简称"
              v-model="form1.shortName"
              autocomplete="off"
            />
          </el-form-item>
        </el-form>
      </div>
      <div
        class="form-input"
        :style="MobileTerminal ? 'padding:18px 23px' : ''"
      >
        <span :style="MobileTerminal ? 'font-size:17px;' : ''">证照信息</span>
        <el-form
          style="text-align: left"
          :rules="rules2"
          ref="form2"
          label-position="top"
          :model="form2"
          hide-required-asterisk
        >
          <el-form-item label="营业执照" prop="businessLicense">
            <el-input
              class="el_input"
              v-model="form2.businessLicense"
              placeholder="请输入营业执照"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item>
            <div>
              <img v-if="licenseNoPic" :src="licenseNoPic" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div style="margin-top: 1rem">
                <input
                  class="inputstyle"
                  type="file"
                  accept="image/*"
                  ref="licenseNoPic"
                  @click="
                    (e) => {
                      e.target.value = '';
                    }
                  "
                  @change="btnUploadFile('licenseNoPic')"
                />
                <el-button style="float: left" type="danger"
                  >点击上传</el-button
                >
              </div>
            </div>
            <div v-if="!MobileTerminal">
              <div class="codeUpload">
                <img class="QcCode" src="" alt="" />
                <div class="Qctitle">扫码上传</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="食品经营许可证">
            <div>
              <img v-if="qualification" :src="qualification" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div style="margin-top: 1rem">
                <input
                  class="inputstyle"
                  type="file"
                  accept="image/*"
                  ref="qualification"
                  @click="
                    (e) => {
                      e.target.value = '';
                    }
                  "
                  @change="btnUploadFile('qualification')"
                />
                <el-button style="float: left" type="danger"
                  >点击上传</el-button
                >
              </div>
            </div>
            <div v-if="!MobileTerminal">
              <div class="codeUpload">
                <img class="QcCode" src="" alt="" />
                <div class="Qctitle">扫码上传</div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="法人身份证" prop="legalIdCard">
            <el-input
              class="el_input"
              placeholder="请输入法人身份证码"
              v-model="form2.legalIdCard"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="身份证人像面">
            <div>
              <img v-if="idCardFrontPic" :src="idCardFrontPic" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div style="margin-top: 1rem">
                <input
                  class="inputstyle"
                  type="file"
                  accept="image/*"
                  ref="idCardFrontPic"
                  @click="
                    (e) => {
                      e.target.value = '';
                    }
                  "
                  @change="btnUploadFile('idCardFrontPic')"
                />
                <el-button style="float: left" type="danger"
                  >点击上传</el-button
                >
              </div>
            </div>
            <div v-if="!MobileTerminal">
              <div class="codeUpload">
                <img class="QcCode" src="" alt="" />
                <div class="Qctitle">扫码上传</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="身份证国徽面">
            <div>
              <img v-if="idCardBackPic" :src="idCardBackPic" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div style="margin-top: 1rem">
                <input
                  class="inputstyle"
                  type="file"
                  accept="image/*"
                  ref="idCardBackPic"
                  @click="
                    (e) => {
                      e.target.value = '';
                    }
                  "
                  @change="btnUploadFile('idCardBackPic')"
                />
                <el-button style="float: left" type="danger"
                  >点击上传</el-button
                >
              </div>
            </div>
            <div v-if="!MobileTerminal">
              <div class="codeUpload">
                <img class="QcCode" src="" alt="" />
                <div class="Qctitle">扫码上传</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="手持个人证件照" prop="phone">
            <div>
              <img v-if="idCardPic" :src="idCardPic" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div style="margin-top: 1rem">
                <input
                  class="inputstyle"
                  type="file"
                  accept="image/*"
                  ref="idCardPic"
                  @click="
                    (e) => {
                      e.target.value = '';
                    }
                  "
                  @change="btnUploadFile('idCardPic')"
                />
                <el-button style="float: left" type="danger"
                  >点击上传</el-button
                >
              </div>
            </div>
            <div v-if="!MobileTerminal">
              <div class="codeUpload">
                <img class="QcCode" src="" alt="" />
                <div class="Qctitle">扫码上传</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="height: 6.25rem">
            <div class="subbtn" v-if="!MobileTerminal">
              <el-button
                style="width: 12.5rem; height: 3rem"
                type="danger"
                @click="onSubmit"
                >确认提交</el-button
              >
              <div>*请确认信息无误后提交</div>
            </div>
            <div class="subbtn1" v-if="MobileTerminal">
              <el-button
                style="display: block; width:80%;"
                type="danger"
                @click="onSubmit"
                >确认提交</el-button
              >
              <div>*请确认信息无误后提交</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      licenseNoPic: '', // 营业执照pic
      idCardFrontPic: '', // 身份证正面pic
      idCardBackPic: '', // 身份证背面pic
      idCardPic: '', // 个人手持身份证pic
      qualification: '', // 食品经营许可pic
      form1: {
        companyName: '', // 公司名称
        companyAddress: '', // 公司地址
        contactName: '', // 联系人
        contactPhone: '', // 电话
        shortName: '' // 公司简称
      },
      form2: {
        businessLicense: '', // 营业执照
        licenseNoPic: '', // 营业执照pic
        qualification: '', // 食品经营许可pic
        legalIdCard: '', // 法人身份证
        idCardFrontPic: '', // 身份证正面pic
        idCardBackPic: '', // 身份证背面pic
        idCardPic: '' // 个人手持身份证pic
      },
      imageUrl: '',
      rules1: {
        companyName: [
          { required: true, message: '请填写公司名称', trigger: 'blur' },
          {
            min: 2,
            max: 30,
            message: '公司名称长度在2-30之间',
            trigger: 'blur'
          }
        ],
        companyAddress: [
          { required: true, message: '请填写地址', trigger: 'blur' },
          { min: 2, max: 40, message: '地址长度在2-40之间', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '请填写联系人', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '联系人姓名长度在2-10之间',
            trigger: 'blur'
          }
        ],
        contactPhone: [
          { required: true, message: '请填写电话', trigger: 'blur' },
          { min: 2, max: 30, message: '电话为11位', trigger: 'blur' }
        ]
      },
      rules2: {
        businessLicense: [
          { required: true, message: '请填写营业执照', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
  },
  methods: {
    uploadMaterial () {
      this.$refs.file.dispatchEvent(new MouseEvent('click'))
    },
    // 触发上传材料文件
    async btnUploadFile (id) {
      const imgFile = this.$refs[id].files[0]
      var formData = new FormData() // 创建form 对象
      formData.append('file', imgFile) // 通过append向form对象添加数据
      formData.append('other', 'other') // 如果还需要传替他参数的话
      const { data: res } = await this.$http.post(
        '/common/imgUpload',
        formData
      )
      if (res.code === 1) {
        this[id] = res.data
      } else {
        this.$message('上传失败')
      }
    },
    async onSubmit (form) {
      this.form2.licenseNoPic = this.licenseNoPic
      this.form2.idCardFrontPic = this.idCardFrontPic
      this.form2.idCardBackPic = this.idCardBackPic
      this.form2.idCardPic = this.idCardPic
      this.form2.qualification = this.qualification
      let validResult1
      let validResult2
      var formData = {}
      this.$refs.form1.validate((valid) => {
        validResult1 = valid
      })
      this.$refs.form2.validate((valid) => {
        validResult2 = valid
      })
      if (validResult1 && validResult2) {
        formData.companyName = this.form1.companyName
        formData.companyAddress = this.form1.companyAddress
        formData.contactName = this.form1.contactName
        formData.contactPhone = this.form1.contactPhone
        formData.shortName = this.form1.shortName
        formData.businessLicense = this.form2.businessLicense
        formData.licenseNoPic = this.form2.licenseNoPic
        formData.qualification = this.form2.qualification
        formData.legalIdCard = this.form2.legalIdCard
        formData.idCardFrontPic = this.form2.idCardFrontPic
        formData.idCardBackPic = this.form2.idCardBackPic
        formData.idCardPic = this.form2.idCardPic
        const { data: res } = await this.$http.post(
          '/common/applyHylAccount',
          formData
        )
        if (res.code === 1) {
          this.$emit('Change', '1')
          this.form1 = {}
          this.form2 = {}
        } else {
          this.$message(res.msg)
        }
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
.inputstyle {
  width: 98px;
  height: 100%;
  background-color: red;
  cursor: pointer;
  font-size: 20px;
  outline: medium none;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.content-title {
  text-align: center;
  height: 7.5rem;
  padding-top: 1.25rem;
  box-sizing: border-box;
  background: #eeeeee;
}
.content-title1 {
  width: 100%;
  background: #ffd0c5;
  text-align: center;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ee2a00;
  padding: 14px 0;
}
.content-form {
  margin: auto;
  width: 75rem;
  margin-top: 2.5rem;
}
.form-label {
  font-size: 0.75rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #606060;
  text-align: left;
  margin-bottom: 2rem;
}
.form-label > span {
  display: block;
  margin: 0.3125rem auto;
}
.form-input {
  /* width: 100%; */
  padding: 3.125rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
}
.form-input > span {
  display: block;
  text-align: left;
  font-size: 2.25rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #111111;
  margin-bottom: 2.5rem;
}
.el_form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
}
.el_input {
  width: 30rem;
  height: 3rem;
}
.codeUpload {
  width: 10rem;
  background: #f5f5f5;
  text-align: center;
  padding-top: 1.25rem;
  position: absolute;
  right: 20px;
}
.QcCode {
  width: 7.5rem;
  height: 7.5rem;
}
.Qctitle {
  height: 1rem;
  line-height: 1rem;
  margin-bottom: 10px;
}
.subbtn {
  margin-top: 3.125rem;
  position: absolute;
  right: 20px;
}
.subbtn1 {
  width: 100%;
  margin-top: 3.125rem;
  text-align: center;
}
/* 上传 */
.avatar-uploader {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
/deep/ .el-form-item__content {
  justify-content: flex-start !important;
  align-items: stretch !important;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
/* 992 像素以下 */
@media only screen and (max-width: 992px) {
  .content-form {
    width: auto !important;
    padding: 0 20px !important;

  }
}
</style>
