import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/Home.vue'
// import Service from '../components/Service.vue'
import CompanyProfile from '../components/CompanyProfile.vue'
import HomePage from '../components/HomePage.vue'
import ContactUs from '../components/ContactUs.vue'
import Registered from '../components/Registered.vue'
import DownloadApp from '../components/DownloadApp.vue'
// import Upload from '@/Upload.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/Home/HomePage' },
  // { path: '/', redirect: '/Home/Registered' }, // 申请表
  {
    path: '/Home',
    component: Home,
    children: [
      { path: 'HomePage', name: 'homePage', component: HomePage },
      { path: 'CompanyProfile', name: 'companyProfile', component: CompanyProfile },
      // { path: 'Service', name: 'service', component: Service },
      { path: 'ContactUs', name: 'contactUs', component: ContactUs },
      { path: 'Registered', name: 'registered', component: Registered },
      { path: 'DownloadApp', name: 'downloadApp', component: DownloadApp }
    ]
  }
  // ,
  // {
  //   path: '/Upload',
  //   name: 'upload',
  //   component: Upload
  // }
]

const router = new VueRouter({
  routes
})

export default router
