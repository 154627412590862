import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import echarts from 'echarts'
import 'echarts/map/js/china'
Vue.prototype.$echarts = echarts
axios.defaults.baseURL = 'https://apis.huoyl.cn/qiaoge'

if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  console.log('当前设备是移动设备')
  Vue.prototype.MobileTerminal = true
  // 当前设备是移动设备
} else {
  console.log('当前设备是pc端')
  Vue.prototype.MobileTerminal = false
}
Vue.prototype.$bus = new Vue()
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
